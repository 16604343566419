import * as ENV from '../env';

export default class LoginManager
{
    loginForm()
    {
        let defaultFormData = {
            username: '',
            password: '',
            rememberme: false
        };

        if (localStorage.getItem('username') != null) {
            // Si le token est déjà renseigné tenter d'accéder au manager
            if (localStorage.getItem('bearer') != null) {
                let newHref = 'orders.php';
                window.location.pathname = newHref;
            }

            // Si la case 'Se souvenir de moi' est cochée, peupler le formulaire
            if (localStorage.getItem('rememberme') != null) {
                defaultFormData = {
                    username: localStorage.getItem('username'),
                    password: '',
                    rememberme: !!localStorage.getItem('rememberme')
                };
            }
        }

        return {
            formData: defaultFormData,

            isSubmitting: false,

            loginError: '',

            submitData()
            {
                this.isSubmitting = true;

                const regex = /<a.*<\/a>/i;
                let formData = this.formData;

                localStorage.setItem('username', formData.username);
    
                // Gestion de l'option 'Se souvenir de moi'
                if (!this.formData.rememberme) {
                    this.formData = {
                        username: '',
                        password: '',
                        rememberme: false
                    };
                    localStorage.removeItem('rememberme');
                } else {
                    this.formData = {
                        username: formData.username,
                        password: '',
                        rememberme: formData.rememberme
                    };

                    localStorage.setItem('rememberme', formData.rememberme);
                }
        
                // Tentative de génération du token
                fetch(ENV.url + 'wp-json/jwt-auth/v1/token/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData)
                })
                .then((response) => {                    
                    return response.json().then((response) => {
                        if (response.token !== undefined) {
                            if (response.restaurant == null || response.restaurant == 0) {
                                this.isSubmitting = false;
                                this.loginError = "Votre compte n'est rattaché à aucun restaurant.";
                                let loginUser = localStorage.getItem('username');
                                let rememberme = localStorage.getItem('rememberme');
                                localStorage.clear();

                                if (rememberme != null && rememberme) {
                                    localStorage.setItem('username', loginUser);
                                    localStorage.setItem('rememberme', rememberme);
                                }
                            } else {
                                // Si la connexion réussit, stocker les données dans le localstorage et récupérer le restaurant rattaché
                                localStorage.setItem('bearer', response.token);
                                localStorage.setItem('id_resto', response.restaurant);
                                let newHref = 'orders.php';
                                window.location.pathname = newHref;

                                // fetch(ENV.url + 'wp-json/ginogina/restaurant/' + localStorage.getItem('id_resto'), {
                                //     method: 'GET',
                                //     headers: { 
                                //         'Content-Type': 'application/json',
                                //         'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                                //     }
                                // })
                                // .then((response) => {
                                //     this.isSubmitting = false;
                                //     return response.json().then((response) => {
                                //         console.log(response);
                                //         if (response.ID) {
                                //             if (parseInt(response.ID) === parseInt(localStorage.getItem('id_resto'))) {
                                //                 localStorage.setItem('restaurant', JSON.stringify(response));
    
                                //                 let newHref = 'orders.php';
                                //                 window.location.pathname = newHref;
                                //             }
                                //         }

                                //         if (JSON.parse(localStorage.getItem('restaurant')) == null) {
                                //             this.loginError = "Le restaurant rattaché à votre compte n'a pas pu être trouvé.";
                                //             let loginUser = localStorage.getItem('username');
                                //             let rememberme = localStorage.getItem('rememberme');
                                //             localStorage.clear();

                                //             if (rememberme != null && rememberme) {
                                //                 localStorage.setItem('username', loginUser);
                                //                 localStorage.setItem('rememberme', rememberme);
                                //             }
                                //         }
                                //     })
                                // });
                            }
                        } else {
                            this.isSubmitting = false;
                            this.loginError = response.message.replace(regex, '');
                            let loginUser = localStorage.getItem('username');
                            let rememberme = localStorage.getItem('rememberme');
                            localStorage.clear();

                            if (rememberme != null && rememberme) {
                                localStorage.setItem('username', loginUser);
                                localStorage.setItem('rememberme', rememberme);
                            }
                        }
                    });
                })
                .catch((error) => {
                    this.isSubmitting = false;
                    if (error) {
                        this.loginError = error.message.replace(regex, '');
                        let loginUser = localStorage.getItem('username');
                        let rememberme = localStorage.getItem('rememberme');
                        localStorage.clear();
    
                        if (rememberme != null && rememberme) {
                            localStorage.setItem('username', loginUser);
                            localStorage.setItem('rememberme', rememberme);
                        }
                    }
                });
            }
        }
    }
}